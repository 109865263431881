import {
  ApplicationConfig,
  ErrorHandler,
  LOCALE_ID,
  Provider,
  importProvidersFrom,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import km from '@angular/common/locales/km';
import { MatDateFormats } from '@angular/material/core';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { NgxMaskConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { GlobalErrorHandler } from './utils/global-error-handler';
registerLocaleData(km);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const MY_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

const localeProvider: Provider = {
  provide: LOCALE_ID,
  useValue: 'km-KH',
};
const maskConfigFunction: () => Partial<NgxMaskConfig> = () => {
  return {
    validation: false,
  };
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(),
    provideOAuthClient(),
    importProvidersFrom([
      TranslateModule.forRoot({
        defaultLanguage: 'km',
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ]),
    localeProvider,
    provideMomentDateAdapter(MY_FORMATS),
    provideEnvironmentNgxMask(maskConfigFunction),
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
};

import { Injectable } from '@angular/core';
import { LocalStorageEnum } from '../models/enums/local-storage-enum';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  get(key: LocalStorageEnum): string {
    const item = localStorage.getItem(key);
    if (item) {
      return item;
    }
    return '';
  }

  set(key: LocalStorageEnum, value: string) {
    localStorage.setItem(key, value);
  }

  setArray(key: LocalStorageEnum, values: any[]) {
    const value = values.toString();
    this.set(key, value);
  }

  getArray(key: LocalStorageEnum): any[] {
    const value = this.get(key);
    return value ? value.split(',') : [];
  }

  delete(key: LocalStorageEnum) {
    localStorage.removeItem(key);
  }

  clearAll() {
    localStorage.clear();
  }
}

export enum MenuViewEnum {
  AccountView = 'account.view',
  AssetView = 'asset.view',
  BankView = 'bank.view',
  ChartOfAccountView = 'coa.view',
  DashboardView = 'dashboard.view',
  DepositView = 'deposit.view',
  FixedInvestmentRequestView = 'fixedInvestmentRequest.view',
  BondInvestmentRequestView = 'bondInvestmentRequest.view',
  EquityInvestmentRequestView = 'equityInvestmentRequest.view',
  FixedInvestmentView = 'fixedInvestment.view',
  IncomeExpenseReportView = 'incomeExpenseReport.view',
  FinancialReportView = 'financialReport.view',
  BondInvestmentView = 'bondInvestment.view',
  EquityInvestmentView = 'equityInvestment.view',
  InvestmentFundView = 'investmentFund.view',
  BondInvestmentPaymentView = 'bondInvestmentPayment.view',
  EquityInvestmentPaymentView = 'equityInvestmentPayment.view',
  InvestmentFundPaymentView = 'investmentFundPayment.view',
  InvestmentPortfolioView = 'investmentPortfolio.view',
  InvestmentPlanView = 'investmentPlan.view',
  comparegrowthratesView = 'investmentPlan.view',
  InvestmentTypeView = 'investmentType.view',
  UserAccountView = 'userAccount.view',
  RoleView = 'role.view',
  WorkflowView = 'workflow.view',
  JournalReportView = 'journalReport.view',
  GeneralLedgerReportView = 'generalLedgerReport.view',
  FundReportView = 'fundReportReport.view',
  CashFlowReportView = 'cashFlowReport.view',
  AdjustmentView = 'adjustment.view',
  BankStatGroupView = 'bankStatGroup.view',
  BankStatView = 'bankStat.view',
}
